let env = {};

env.baseURL = 'https://api.tihrio-homolog.ares-saude.com.br/api/v1/';
env.normalURL = 'https://api.tihrio-homolog.ares-saude.com.br/';
// env.baseURL = 'http://localhost:8000/api/v1/';
// env.normalURL = 'http://localhost:8000/';
env.mode = 'homolog';
//env.mode = 'contingencia';
//env.mode = 'production';
//env.mode = 'development';

export default env
